import { default as index5G9HCiDMfwMeta } from "/app/pages/apis/balvou/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
export default [
  {
    name: index5G9HCiDMfwMeta?.name ?? "apis-balvou",
    path: index5G9HCiDMfwMeta?.path ?? "/apis/balvou",
    meta: index5G9HCiDMfwMeta || {},
    alias: index5G9HCiDMfwMeta?.alias || [],
    redirect: index5G9HCiDMfwMeta?.redirect || undefined,
    component: () => import("/app/pages/apis/balvou/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  }
]