import en from './en.json';
import pt from './pt.json';
import es from './es.json';
import ur from './ur.json';

const messages = {
  en: en,
  pt: pt,
  es: es,
  ur: ur
};

export default messages;
